import {type LinksFunction} from '@remix-run/server-runtime';
import {useLoaderData} from '@remix-run/react';

import PageLayout from '@/components/shared/Page/PageLayout';
import {ColorSchemeEnum} from '@/enums';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {TopicCard} from '@/components/blog/TopicCard/TopicCard';
import {useTranslations} from '@/components/shared/Page/I18N';
import Typography from '@/components/base/elements/Typography/Typography';
import {BLOG_PAGE_TYPE, type Topic} from '@/components/blog/types';
import Conversion, {
  CtaStyles,
  EnterpriseHeadingStyles,
} from '@/components/pages/blog/SubscribeForm/Conversion/Conversion';
import {BlogSubNav} from '@/pages/shopify.com/($locale)/enterprise/blog/components/BlogSubNav/BlogSubNav';
import blogStylesheet from '@/stylesheets/enterprise/blog/styles.css?url';
import {BlogConversion} from '@/components/blog/BlogConversion/BlogConversion';
import {EN_ENTERPRISE_BLOG_PATH} from '@/pages/shopify.com/($locale)/enterprise/blog/route';
import type {topicsLoader} from '@/pages/shopify.com/($locale)/enterprise/blog/topics/server/topicsLoader.server';
import {useBlogPageTitle} from '@/hooks/blog/useBlogPageTitle';

import TopicHeroSection from '../components/TopicHeroSection/TopicHeroSection';

export {topicsLoader as loader} from '@/pages/shopify.com/($locale)/enterprise/blog/topics/server/topicsLoader.server';
export {combinedLeadAction as action} from '@/pages/shopify.com/($locale)/enterprise/blog/server/action.server';
export {enterpriseHandle as handle} from '@/pages/shopify.com/($locale)/enterprise/blog/handle';

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: blogStylesheet}];
};

type TopicCard = {
  title: string;
  description: string;
  handle: string;
  image?: string;
  hidden?: boolean;
};

export default function Topics() {
  const {t, localizePath} = useTranslations();
  const {topics, subNavTopics, settings} = useLoaderData<typeof topicsLoader>();
  const pageTitle = useBlogPageTitle(t('htmlHead.pageTitle'));

  return (
    <PageLayout
      title={pageTitle}
      header={(props) => (
        <BlogSubNav
          {...props}
          subNavTopics={subNavTopics}
          enBlogPath={EN_ENTERPRISE_BLOG_PATH}
        />
      )}
      footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}
      isPureTitle
    >
      <div className="pt-36 bg-hero-dark-green-bg">
        <TopicHeroSection>{t('hero')}</TopicHeroSection>
        <div className="bg-white">
          <div className="container py-16">
            <Typography
              as="h2"
              className="text-t4 desktop:text-t3 font-faktum mb-8 desktop:mb-12 !font-medium"
            >
              {t('featuredTopics')}
            </Typography>
            <div className="grid grid-cols-1 tablet:grid-cols-3 gap-4 desktop:gap-6 tablet:gap-y-6 desktop:gap-y-9">
              {subNavTopics &&
                subNavTopics.map((topic: Topic, index: number) => (
                  <TopicCard
                    key={index}
                    image={topic.image}
                    title={topic.title}
                    description={topic.description}
                    className="border-t border-t-shade-30 !font-faktum [&_>h3]:font-faktum [&_>h3]:!text-[18px] tablet:[&_>h3]:!text-[20px]"
                    linkText={t('linkText')}
                    linkHref={localizePath(
                      `${EN_ENTERPRISE_BLOG_PATH}topics/${topic.handle}`,
                    )}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="subscribe-section subscribe-banner-bg tablet:py-8 desktop:py-16 relative">
          <Conversion
            {...t('conversion')}
            headingClassName={EnterpriseHeadingStyles}
            className="mx-margin py-8 tablet:py-16"
            cta={{...t('conversionSubscription.conversionCta')}}
            mode="dark"
            ctaClassName={CtaStyles({blogType: BLOG_PAGE_TYPE.ENTERPRISE})}
            subscriptionId={settings.subscription.settings.subscriptionId}
          />
        </div>
        <div className="bg-white">
          {topics?.length > 0 && (
            <div className="container pt-10 pb-28 desktop:pt-16">
              <Typography
                as="h2"
                className="text-t5 desktop:text-t4 font-faktum mb-8 desktop:mb-12 !font-medium"
              >
                {t('moreTopics')}
              </Typography>
              <div className="grid grid-cols-1 tablet:grid-cols-3 desktop:grid-cols-4 gap-4 desktop:gap-6 tablet:gap-y-6 desktop:gap-y-9">
                {topics.map((topic: Topic, index: number) => (
                  <TopicCard
                    key={index}
                    image={topic.image}
                    title={topic.title}
                    description={topic.description}
                    className="border-t border-t-shade-30 !font-faktum [&_>h3]:font-faktum"
                    linkText={t('linkText')}
                    linkHref={localizePath(
                      `${EN_ENTERPRISE_BLOG_PATH}topics/${topic.handle}`,
                    )}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <BlogConversion
          heading={t('sellAnywhere.headingHtml')}
          subhead={t('sellAnywhere.subheadHtml')}
          buttonHref={t('sellAnywhere.button.href')}
          buttonText={t('sellAnywhere.button.text')}
          mode="enterprise"
        />
      </div>
    </PageLayout>
  );
}
